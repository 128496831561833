<template>
  <sm-editable-list
    class="reminders"
    :key="listRenderKey"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :filterFields="filterFields"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :validatePermission="false"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('ReminderCreate')"
    @edit="(id) => onEdit('ReminderEdit', id)"
  >
    <!-- <template #list-top-panet>
      <div class="reminder-list__top-panel">
        <sm-button
          class="reminders__button-create-many"
          @click="$router.push({ name: 'RemindersCreateMany' })"
          >Множественное создание</sm-button
        >
      </div>
    </template> -->

    <template v-slot:account="{ row }">
      {{ setAccountField(row.accountId) }}</template
    >

    <template v-slot:whenRemind="{ row }">{{
      $moment(row.whenRemind, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')
    }}</template>

    <template v-slot:relatedObject="{ row }">
      <router-link
        v-show="row.relatedObjectId"
        class="editable-list__link"
        to=""
        @click.native="
          $router.push({
            name: 'TelemarketingTaskForCurrentUserEdit',
            params: { id: row.relatedObjectId },
          })
        "
      >
        Задание по телемаркетингу</router-link
      >
    </template>

    <template v-slot:closeButton="{ row }">
      <sm-button
        v-show="row.isClosed === 'Heт'"
        @click="onCloseReminder(row.id)"
        >Закрыть</sm-button
      >
    </template>
  </sm-editable-list>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';

// mixins
import editableList from '@/mixins/editableList.js';

// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import smButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'Reminders',

  components: {
    SmEditableList,
    smButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Reminders',
      tableCaption: 'Напоминания',
      breadcrumbs: [
        {
          text: 'Напоминания',
        },
      ],
      tableHeaders: [
        {
          text: 'Аккаунт',
          order: 'accountId',
          alias: 'account',
        },
        {
          text: 'Дата',
          order: 'whenRemind',
          alias: 'whenRemind',
        },
        {
          text: 'Комментарий',
          order: 'comment',
          alias: 'comment',
        },
        {
          text: 'Связанный объект',
          order: 'relatedObject',
          alias: 'relatedObject',
        },
        // {
        //   text: 'Тип связанного объекта',
        //   order: 'relatedObjectType',
        //   alias: 'relatedObjectType',
        // },
        // {
        //   text: 'Связанный объект',
        //   order: 'relatedObjectId',
        //   alias: 'relatedObjectId',
        // },
        {
          text: 'Закрыто',
          order: 'isClosed',
          alias: 'isClosed',
        },
        {
          alias: 'closeButton',
        },
        {
          alias: 'actions',
        },
      ],
      filterFields: [
        {
          text: 'Дата',
          alias: 'whenRemind',
        },
        {
          text: 'Комментарий',
          alias: 'comment',
        },
        {
          text: 'Закрыто?',
          alias: 'isClosed',
        },
        {
          text: 'Актуальные?',
          alias: 'isActual',
        },
      ],
      listRenderKey: 0,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
      reminderRelatedObjectTypes: (state) =>
        state.common.reminderRelatedObjectTypes,
    }),
  },

  created() {
    this.getActualReminders();

    this.getCommonList({
      name: 'ReminderRelatedObjectTypes',
    });

    this.getCommonList({ name: 'EmployeeAccounts' });
  },

  methods: {
    ...mapActions({
      getActualReminders: 'reminders/getActualReminders',
      closeReminder: 'reminders/closeReminder',
      getListforCommon: 'editableList/getListforCommon',
      getCommonList: 'common/getCommonList',
    }),

    setAccountField(accountId) {
      const account = this.employeeAccounts.data?.find(
        (item) => item.id === accountId
      );
      if (account) {
        return account?.name;
      }
      return accountId;
    },

    setReminderRelatedObjectTypes(relatedObjectType) {
      const currentRelatedObjectType =
        this.reminderRelatedObjectTypes.data.find(
          (item) => item.id === relatedObjectType
        );
      if (currentRelatedObjectType) {
        return currentRelatedObjectType?.name;
      }
      return relatedObjectType;
    },

    onCloseReminder(id) {
      this.closeReminder({ id }).then(({ isSucceed }) => {
        if (isSucceed) {
          this.listRenderKey += 1;
          this.getActualReminders();
          this.$notify({
            header: 'Напоминание закрыто.',
            type: 'success',
            timer: 5000,
          });
        }
      });
    },
  },
};
</script>

<!-- <style lang="scss">
.reminders__button-create-many {
  width: auto;
}

.reminder-list__top-panel {
  display: flex;
  justify-content: flex-end;
}
</style> -->
