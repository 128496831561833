var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-editable-list',{key:_vm.listRenderKey,staticClass:"reminders",attrs:{"controllerName":_vm.controllerName,"breadcrumbs":_vm.breadcrumbs,"filterFields":_vm.filterFields,"tableCaption":_vm.tableCaption,"tableHeaders":_vm.tableHeaders,"validatePermission":false,"showCreateButton":"","showEditButton":"","showDeleteButton":""},on:{"create":function($event){return _vm.onCreate('ReminderCreate')},"edit":function (id) { return _vm.onEdit('ReminderEdit', id); }},scopedSlots:_vm._u([{key:"account",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.setAccountField(row.accountId)))]}},{key:"whenRemind",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$moment(row.whenRemind, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')))]}},{key:"relatedObject",fn:function(ref){
var row = ref.row;
return [_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(row.relatedObjectId),expression:"row.relatedObjectId"}],staticClass:"editable-list__link",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.$router.push({
          name: 'TelemarketingTaskForCurrentUserEdit',
          params: { id: row.relatedObjectId },
        })}}},[_vm._v(" Задание по телемаркетингу")])]}},{key:"closeButton",fn:function(ref){
        var row = ref.row;
return [_c('sm-button',{directives:[{name:"show",rawName:"v-show",value:(row.isClosed === 'Heт'),expression:"row.isClosed === 'Heт'"}],on:{"click":function($event){return _vm.onCloseReminder(row.id)}}},[_vm._v("Закрыть")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }